<template>
  <nav>
  </nav>
  <router-view/>
</template>

<script>
  
</script>

<style lang="scss">


  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  @font-face {
    font-family: 'OpenSans-Regular';
    src: url(./assets/font/OpenSans-Regular.ttf);
    font-style: normal;
  }

  @font-face {
    font-family: 'OpenSans-Medium';
    src: url(./assets/font/OpenSans-Medium.ttf);
    font-style: normal;
  }
  @font-face {
    font-family: 'OpenSans-Bold';
    src: url(./assets/font/OpenSans-Bold.ttf);
    font-style: normal;
  }

  .bold {
    font-family: 'OpenSans-Bold';
  }
  html { 
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
  }

  body {
    font-family: OpenSans-Regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333333;
    line-height: 1;
    font-size: 0.9em;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
  }

  .input-group-prepend {
    margin-right: -1px;
  }

  .input-group-append, .input-group-prepend {
    display: flex;
  }

  .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn, .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text, .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn, .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text, .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group>.input-group-append:last-child>.input-group-text:not(:last-child), .input-group>.input-group-prepend>.btn, .input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-append .btn, .input-group-prepend .btn {
    position: relative;
    z-index: 2;
  }
  

  .input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }

  .input-group {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
  }

  .input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child), .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label, .input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label:after, .input-group.has-validation>.custom-select:nth-last-child(n+3), .input-group.has-validation>.form-control:nth-last-child(n+3), .input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label, .input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label:after, .input-group:not(.has-validation)>.custom-select:not(:last-child), .input-group:not(.has-validation)>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .form-group{
    margin-top: 1em;
    .form-control {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 1.5em 0.75rem;
      font-size: 0.9em;
      line-height: 1.5;
      color: #495057;
      background-color: #FFF;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 3px;
      outline: none;

      &.error {
        border: 2px solid #e74c3c;
      }
    }
  }

  button {
    &:disabled,
    &[disabled]{
      cursor: not-allowed !important;
    }
  }
  .text-center {
    text-align: center;
  }
  .btn {
    
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 3px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
  }

  .btn-main {
    background: #0091ea;
    width: 100%;
    color: #FFF;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 0.6rem 0.75rem;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    &:hover {
      background: darken(#0091ea, 10%);
    }

    svg {
      width: 20px;
      margin-left: 0.25em;
    }
  }

  .orange {
    color: #8CC63F;
  }
  .white {
    color: #FFF;
  }

  .cancel-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    letter-spacing: 0.67px;
    font-size: 0.9em;
    a {
      color: #808080;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .btn-secondary {
    background: transparent;
    border: 1px solid #FFFFFF;
    width: 100%;
    color: #FFF;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 0.6rem 0.75rem;
    font-size: 0.9em;
    letter-spacing: 0.62px;
    &:hover {
      background: lighten(#212123, 10%);
    }
  }

  .help-block {
    color: #e74c3c;
    font-size: 0.85em;
    margin-top: 0.25em;
  }

  .alert { 
    font-family: 'OpenSans-Bold';
    padding: 0.75em;
    font-size: 0.9em;
    color: #FFF;
    margin-top: 1em;
    border-radius: 3px;
  }
  .alert-info {
    background-color: #3498db;
  }

  .alert-success {
    background-color: #4BB543;
  }
  .alert-danger {
    background: #a94442; 
  }

  .otp-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 1em;
    margin-top: 1em;
    div {
      margin: 0 0.25em;

      .otp-input {
        width: 45px;
        text-align: center;
        border-radius: 6px;
        font-size: 1.5em;
        height: 55px;
        padding: 0.25em 0.25em;
        background-color: #eaeaea;
        border: none;
        outline: none;
        box-shadow: none;

        &.error {
          border: 2px solid #e74c3c;
        }
      }
    }
  }

  .modal-close {
    position: absolute;
    left: 1em;
    cursor: pointer;  
    svg {
      transform: scale(1.25);
    }
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {

    width: 500px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    border-radius: 6px;
    color: #666;
  }

  .modal-container-lg { 
    width: 650px;
  }

  .modal-container-sm { 
    width: 300px;
  }
  .modal-body {
    padding: 1em;
  }
  .modal-header {
    position: relative;
    padding: 1em;
    text-align: center;
    background-color: #4D4D4D;
    color: #FFF;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-family: 'OpenSans-Medium';
    font-size: 0.9em;
    letter-spacing: 1.2px;

    .close-container {
      position: absolute;
      right: 1em;
    }
  }

  label {
    color: #4D4D4D;
    letter-spacing: 0.65px;
  }
  
   /* width */
    ::-webkit-scrollbar {
       width: 5px;
       height: 5px;
    }
  /* Track */
    ::-webkit-scrollbar-track {
      background: lighten(#212123, 20%)
   }

  /* Handle */
   ::-webkit-scrollbar-thumb {
      background: lighten(#212123, 10%)
   }

  /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
       background: #555;
   }

  ::-webkit-scrollbar-corner { 
    background: lighten(#212123, 20%);
  }

  @keyframes spin {
    0% {
     transform: rotate(0deg);
    }
    100% {
     transform: rotate(360deg);
    }
  }

  .spinner {
    animation: spin 2s linear infinite;
  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    display: block;
    width: 200px;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(0, 0%, 20%);
    }
    100% {
      background-color: hsl(0, 0%, 40%);
    }
  }
</style>
