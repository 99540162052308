import { createRouter, createWebHashHistory } from 'vue-router'



const routes = [
  {
    path: '/:merchantId',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "Dashboard" */ '@/components/Dashboard')
    }
  },
  {
    path: '/maya/:merchantId',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "Dashboard" */ '@/components/Dashboard')
    }
  },
  {
    path: '/maya/:whitelabel/:merchantId',
    name: 'MayaDashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "Dashboard" */ '@/components/Dashboard')
    }
  },
  {
    path: '/bux/:merchantId',
    name: 'BuxPayment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "Dashboard" */ '@/components/BuxPayment')
    }
  },
  {
    path: '/qrph/:merchantId',
    name: 'QrPayment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "Dashboard" */ '@/components/QrPayment')
    }
  },
  {
    path: '/gcash/:merchantId',
    name: 'Gcash',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "Dashboard" */ '@/components/Gcash')
    }
  },
  { path: '/:pathMatch(.*)*', component: ()=> { window.location.href="https://secure.paynet.io" } },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
